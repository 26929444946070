import Loader from '@gameonsports/components/cjs/Loader'
import loadable from '@loadable/component'
import { RouteComponentProps, useNavigate } from '@reach/router'
import React, { useEffect } from 'react'
import { AppFooterBanner } from '../../components/AppFooterBanner/AppFooterBanner'
import PageLoader from '../../components/PageLoader'
import { TenantProvider } from '../../contexts/TenantContext/TenantContext'
import { useTenantQuery } from '../../generated/graphql'
import { tenantSlugRedirects } from '../../utils/tenant'

const NotFound = loadable(
  () => import('../404' /* webpackChunkName: "404" */),
  { fallback: <PageLoader /> },
)

const Tenant: React.FC<
  RouteComponentProps<{
    tenant: string
  }>
> = ({ tenant, children, location }) => {
  const navigate = useNavigate()

  const { error, loading, data } = useTenantQuery({
    // Always get a clean version of the tenant
    // If we can pass in the tenant slug as a variable we dont need to
    // set this as cache-and-network as is will cache things correctly.
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (tenant && Object.keys(tenantSlugRedirects).includes(tenant)) {
      navigate(
        `${location?.pathname.replace(
          `/${tenant}`,
          `/${tenantSlugRedirects[tenant]}`,
        )}${location?.search}`,
        { replace: true },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant])

  if (loading) {
    return <Loader />
  }

  if (error || !data?.tenantConfiguration?.label) {
    return <NotFound />
  }

  return (
    <TenantProvider tenant={tenant}>
      {children}
      {tenant && <AppFooterBanner tenant={tenant} />}
    </TenantProvider>
  )
}

export default Tenant
