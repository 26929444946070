import { cloneDeep } from 'lodash'
import { isSSR } from './ssr'

type TrackEventObject = {
  event: string | undefined
  [key: string]: unknown
}

type TrackObject = {
  [key: string]: unknown
}

export function track(obj: TrackEventObject) {
  if (!isSSR) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(safeObject(cloneDeep(obj)))
  }
}

const emailRegex =
  /[a-zA-Z0-9.!#$%&'*+/=?^_*{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/g

const safeObject = (obj: TrackObject) => {
  Object.entries(obj).forEach(([key, value]) => {
    if (!value) return

    if (typeof value === 'object') {
      safeObject(value as TrackObject)
      return
    }

    if (typeof value === 'string' && emailRegex.test(value)) {
      obj[key] = value.replace(emailRegex, '(redacted)')
      return
    }
  })

  return obj
}
