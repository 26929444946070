import { isSSR } from './ssr'

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android' or undefined.
 */
export const getMobileOperatingSystem = () => {
  if (isSSR) return undefined
  const userAgent = navigator.userAgent || navigator.vendor
  if (/android/i.test(userAgent)) return 'Android'
  if (/iPhone|iPod/.test(userAgent)) return 'iOS'
  return undefined
}
